.chargeSession_container {
  display: flex;

  padding: 10px;
  border-radius: 12px;
  height: 80px;
  background-color: #F6F8FA;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  .title {
    font-weight: bold;
  }
 
 
}
@media screen and (max-width: 575px) {
  .csWidget {
    margin-bottom: 20px;
    width: -webkit-fill-available;
  }
}
